import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { returnActualCourseNameForApi } from '../services/getcourse';
import { getProjects } from '../services/projectApiService';
import { COURSE_TYPE } from 'src/constants';

const initialState = {
  newProjects: null,
  submittedProjects: null,
  allProjects: null,
  currTab: 1,
};

const projectSlice = createSlice({
  name: 'projectReducer',
  initialState,
  reducers: {
    updateProjects: (state, action) => {
      state.newProjects = action.payload.projectData;
      state.submittedProjects = action.payload.submittedProjects;
      state.allProjects = action.payload.allProjects;
    },
    setCurrTab: (state, action) => {
      state.currTab = action.payload;
    },
    resetAllProjectsData: (state, action) => {
      state.newProjects = null;
      state.submittedProjects = null;
      state.allProjects = null;
    },
  },
});

export function fetchProjects() {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;

      console.log(userSubscription, 'userSubscription');
      const course = getState().courseReducer.courseSelected;
      const masters = [COURSE_TYPE.MASTERS_CS, COURSE_TYPE.MASTERS_DS_AS];
      const isMasters = masters.includes(course);
      console.log(isMasters, 'isMasters');
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);

      const data = await getProjects(modifiedCourse, isMasters);
      const projectData = {};
      const submittedProjects = {};
      data.data.sort((a, b) => {
        if (b?.attributes?.createdAt) {
          return dayjs(b?.attributes?.createdAt) - dayjs(a?.attributes?.createdAt);
        }
        return -1;
      });

      data?.data?.forEach((item) => {
        const attributs = { ...item?.attributes, projectId: item?.id };
        if (item?.attributes?.solvedStatus === 'unsolved') {
          const module = item?.attributes?.module;
          if (projectData?.[module]) {
            projectData[module] = [...projectData[module], attributs];
          } else {
            projectData[module] = [attributs];
          }
        } else {
          const module = item?.attributes?.module;
          if (submittedProjects?.[module]) {
            submittedProjects[module] = [...submittedProjects[module], attributs];
          } else {
            submittedProjects[module] = [attributs];
          }
        }
      });
      dispatch(updateProjects({ projectData, submittedProjects, allProjects: data?.data }));
    } catch (error) {
      console.error(error);
    }
  };
}

export const { updateProjects, setCurrTab, resetAllProjectsData } = projectSlice.actions;

export default projectSlice.reducer;
