import _ from 'lodash';
import { returnCourseApiName } from './services/getcourse';
export const getDomain = () => {
  const domain = `${window.location.protocol}//${window.location.host}`;
  return domain;
};

const DOMAIN = getDomain() || 'http://localhost:3000';

export const ALMABETTER_HOME_LINKS = {
  ROOT: 'https://www.almabetter.com',
  get TERM_AND_CONDITIONS() {
    return `${this.ROOT}/terms-of-use`;
  },
  get PRIVACY() {
    return `${this.ROOT}/privacy-policy`;
  },
  get ABOUT_US() {
    return `${this.ROOT}/about-us`;
  },
  get COMMUNITY() {
    return `${this.ROOT}/community`;
  },
};

export const THINKIFIC_ALMABETTER_LINKS = {
  ROOT: 'https://learn.almabetter.com',
  get DATA_SCIENCE_PRO_LIVE_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-live-onboarding/multimedia/27676716-almax-pre-registration-form`;
  },
  get DATA_SCIENCE_PRO_FLEX_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-flex-onboarding/multimedia/27488097-almax-pre-registration-form`;
  },
  get WEB3_PRO_LIVE_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/web-development-pro-program-onboarding/multimedia/33533152-almax-pre-registration-form`;
  },
  get ENROLLMENTS_PAGE() {
    return `${this.ROOT}/enrollments`;
  },
  get SSO_LOGOUT_URL() {
    return `${this.ROOT}/users/sign_out`;
  },
};

export const IMAGE_FILE_SIZE_MAX = 1449616;

export const THINKIFIC_CONSTANTS = {
  SSO_LOGIN: {
    URL: 'https://almabetter.thinkific.com/api/sso/v2/sso/jwt',
    ERROR_URL: `${DOMAIN}/thinkific_login_error`,
  },
};

export const LOCAL_STORAGE_KEYS = {
  REDIRECT_TO: 'REDIRECT_TO',
  LOGIN_PROCESS_STAGE: 'LOGIN_PROCESS_STAGE',
  LOGOUT_PROCESS_STAGE: 'LOGOUT_PROCESS_STAGE',
  COURSE_INTERESTS: 'COURSE_INTERESTS',
  REFERRAL_CODE: 'REFERRAL_CODE',
  REFERRAL_GOAL: 'REFERRAL_GOAL',
  REFERRAL_PLAN: 'REFERRAL_PLAN',
  LINKEDIN_SHARE: 'LINKEDIN_SHARE',
  REFERRAL_SOURCE: 'REFERRAL_SOURCE',
  JOB_DASHBOARD_VIEWED: 'JOB_DASHBOARD_VIEWED',
  GLOBAL_SETTINGS: 'GLOBAL_SETTINGS',
  CAMPAIGN_PARAMS: 'CAMPAIGN_PARAMS',
};

export const LOGIN_LOGOUT_PROCESS_STAGE_VALUES = {
  COGNITO_AUTH_SUCCESSFUL: 'COGNITO_AUTH_SUCCESSFUL',
  LOGOUT_STARTED: 'LOGOUT_STARTED',
};

export const ASSIGNMENT_SUBMISSION_INSTRUCTION_VIDEO_URL =
  'https://ab-public-bucket-dev.s3.ap-south-1.amazonaws.com/submissionPart1.mp4';
export const CAPSTONE_PROJECT_SUBMISSION_INSTRUCTION_VIDEO_URL =
  'https://ab-public-bucket-dev.s3.ap-south-1.amazonaws.com/capstoneProjectVideo.mp4';

export const COURSES_OFFERED = ['data-science', 'wev-dev'];

export const RAZORPAY = {
  SCRIPT_SRC: 'https://checkout.razorpay.com/v1/checkout.js',
  KEY_ID: process.env.REACT_APP_RAZORPAY_KEY_ID || 'rzp_test_gVz50FqbpOF707',
  CURRENCY: 'INR',
};

export const ALMABETTER_LOGO_URL =
  'https://ab-public-bucket-prod.s3.ap-south-1.amazonaws.com/razorpay_icon.png';

export const PLAN_TYPE = {
  PRO_FLEX: 'pro_flex',
  PRO_LIVE: 'pro_live',
  PRO_LEGACY: 'pro_legacy',
  PRO_UPGRADE: 'pro_upgrade',
  NEW_USER: 'new_user',
  PREMIUM: 'premium',
  CORPORATE: 'corporate_training',
  MASTER: 'masters',
};

export const PLAN_TYPE_NAMES_MAPPING = {
  [PLAN_TYPE.PRO_FLEX]: 'Pro Flex',
  [PLAN_TYPE.PRO_LIVE]: 'Pro Live',
  [PLAN_TYPE.PRO_LEGACY]: 'Pro User',
  [PLAN_TYPE.NEW_USER]: 'New User',
  [PLAN_TYPE.PREMIUM]: 'Premium',
  [PLAN_TYPE.MASTER]: 'Masters',
};

export const COURSE_TYPE = {
  DATA_SCIENCE: 'data-science',
  WEB_DEV: 'web-dev',
  DATA_ENGINEERING: 'data-engineering',
  DS_BOOTCAMP: 'ds-bootcamp',
  MASTERS_DS_AS: 'masters-ds',
  MASTERS_CS: 'masters-cs',
  UG_DIPLOMA_CS: 'ug-diploma-cs',
  WEB3: 'web3',
};

export const COURSE_TYPE_NAMES_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Certification in Full Stack Data Science and AI',
  [COURSE_TYPE.WEB_DEV]: 'Certification in Full Stack Web Development',
  [COURSE_TYPE.DS_BOOTCAMP]: 'Data Science Ninja Bootcamp',
  [COURSE_TYPE.DATA_ENGINEERING]: 'Full Stack Data Engineering',
  [COURSE_TYPE.MASTERS_DS_AS]: 'Masters in CS : Artificial Intelligence and Machine Learning',
  [COURSE_TYPE.MASTERS_CS]: 'Masters in Computer Science : Cloud Computing',
  [COURSE_TYPE.UG_DIPLOMA_CS]: 'UG Diploma in Computer Science',
};

export const BOOKING_STAGE = {
  CREATED: 'created',
  TIME_SLOTS_ADDED: 'timeSlotsAdded',
  COACH_ASSIGNED: 'coachAssigned',
  SESSION_CONDUCTED: 'sessionConducted',
  FEEDBACK_SUBMITTED: 'feedbackSubmitted',
  ADMIN_ON_HOLD: 'adminOnHold',
  ADMIN_APPROVED: 'adminApproved',
  CANCELLED: 'cancelled',
};

export const BOOKING_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  DEFAULTED: 'defaulted',
};

export const USER_CREDITS_DEDUCTION_AMOUNT = 15;
export const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://strapiv2.almagrow.com/api/';

export const COMMUNITY = 'https://community.almabetter.com/';
export const DATE_FORMAT_1 = 'Do MMM YYYY, hh:mm A';
export const DATE_ONLY_FROMAT_1 = 'Do MMM YYYY';
export const DATE_ONLY_FROMAT_2 = 'dddd, DD MMM YYYY';

export const MENTORSHIP_RESOURCE_LINK =
  'https://almabetter.notion.site/Mentorship-65a81629919b4ee1b26efaa393d6851c';

export const FRESHDESK_SUPPORT_URL =
  'https://almabetter.myfreshworks.com/login/auth/1662641230821?client_id=451980303908285122&redirect_uri=https%3A%2F%2Falmabetter-support.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dsupport.almabetter.com';

export const PAYMENT_ONBOARDING_FORM_STAGE = {
  BASIC_INFO: 'BASIC_INFO',
  EDUCATION: 'EDUCATION',
  WORK_EXPERIENCE: 'WORK_EXPERIENCE',
  SOCIAL: 'SOCIAL',
  SKILLS: 'SKILLS',
  GOAL: 'GOAL',
};

export const UG_PAYMENT_ONBOARDING_FORM_STAGE = {
  BASIC_INFO: 'BASIC_INFO',
  TWELTH_EDUCATION: '12_EDUCATION',
  GRADUATION_EDUCATION: 'GRADUATION_EDUCATION',
  GOAL: 'GOAL',
};

const COURSE_PATHS = {
  FULL_STACK_DATA_SCIENCE: 'full-stack-data-science',
  FULL_STACK_WEB_DEVELOPMENT_WITH_WEB3: 'full-stack-web-development-with-web3',
  DS_BOOTCAMP: 'ds-bootcamp',
  ALMAX_THE_LAST_MILE: 'almax-the-last-mile-new',
  DATA_ENGINEERING: 'full-stack-data-engineering',
  MASTERS_DS_AS: 'masters-ds',
  MASTERS_CS: 'masters-cs',
  UG_DIPLOMA_CS: 'ug-diploma-cs',
};
export const COURSE_TYPE_COURSE_PATH_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: COURSE_PATHS.FULL_STACK_DATA_SCIENCE,
  [COURSE_TYPE.WEB_DEV]: COURSE_PATHS.FULL_STACK_WEB_DEVELOPMENT_WITH_WEB3,
  [COURSE_TYPE.DS_BOOTCAMP]: COURSE_PATHS.DS_BOOTCAMP,
  [COURSE_TYPE.DATA_ENGINEERING]: COURSE_PATHS.DATA_ENGINEERING,
  [COURSE_TYPE.MASTERS_DS_AS]: COURSE_PATHS.MASTERS_DS_AS,
  [COURSE_TYPE.MASTERS_CS]: COURSE_PATHS.MASTERS_CS,
  [COURSE_TYPE.UG_DIPLOMA_CS]: COURSE_PATHS.UG_DIPLOMA_CS,
};

export const COURSE_PATH_COURSE_TYPE_MAPPING = _.invert(COURSE_TYPE_COURSE_PATH_MAPPING);
export const getCriteriaForGivenCoursePath = (coursePath) => {
  if (COURSE_PATH_COURSE_TYPE_MAPPING[coursePath]) {
    const courseType = COURSE_PATH_COURSE_TYPE_MAPPING[coursePath];
    return { courseType };
  } else if (coursePath === COURSE_PATHS.ALMAX_THE_LAST_MILE) {
    return { courseType: COURSE_TYPE.DATA_SCIENCE, almax: true };
  }
  return {};
};
export const WEB_SOCKETS_ENDPOINT =
  process.env.REACT_APP_WEB_SOCKETS_ENDPOINT ||
  'wss://7nq4wvs58h.execute-api.ap-south-1.amazonaws.com/dev';

export const DISCORD_AUTH_CLIENT_ID =
  process.env.REACT_APP_DISCORD_AUTH_CLIENT_ID || '1098890425391132684';

export const COMMUNITY_EVENT_TYPES = {
  PAYMENT: 'payment',
  PLACEMENT: 'placement',
  ROUND_ASSIGNED: 'round-assigned',
  COURSE_COMPLETION: 'course-completion',
  PROJECT_COMPLETED: 'project-completed',
  ASSESSMENT_COMPLETED: 'assessment-completed',
  MODULE_COMPLETED: 'module-completed',
};

export const COURSE_SELECTOR = {
  masters: [
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.MASTERS_DS_AS],
      value: COURSE_TYPE.DATA_SCIENCE,
      planType: PLAN_TYPE.MASTER,
      courseType: COURSE_TYPE.MASTERS_DS_AS,
      duration: '12 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: true,
        iitGuwahati: true,
      },
      for: 'Graduates & Working Professionals',
      outcome: `5-40 LPA Job + Master's Degree`,
    },
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.MASTERS_CS],
      value: returnCourseApiName(COURSE_TYPE.WEB_DEV),
      planType: PLAN_TYPE.MASTER,
      courseType: COURSE_TYPE.MASTERS_CS,
      duration: '12 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: true,
        iitGuwahati: true,
      },
      for: 'Graduates & Working Professionals',
      outcome: `5-40 LPA Job + Master's Degree`,
    },
  ],
  certification: [
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.DATA_SCIENCE],
      value: COURSE_TYPE.DATA_SCIENCE,
      planType: PLAN_TYPE.PRO_LIVE,
      courseType: COURSE_TYPE.DATA_SCIENCE,
      duration: '6 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: false,
        iitGuwahati: true,
      },
      for: 'College students, graduates & working professionals.',
      outcome: '5-25 LPA Job + Certification',
    },
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.WEB_DEV],
      value: returnCourseApiName(COURSE_TYPE.WEB_DEV),
      planType: PLAN_TYPE.PRO_LIVE,
      courseType: COURSE_TYPE.WEB_DEV,
      duration: '6 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: false,
        iitGuwahati: true,
      },
      for: 'College students, graduates & working professionals.',
      outcome: '5-25 LPA Job + Certification',
    },
  ],

  // ugCourses: [
  //   {
  //     name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.UG_DIPLOMA_CS],
  //     value: COURSE_TYPE.UG_DIPLOMA_CS,
  //     planType: PLAN_TYPE.PRO_LIVE,
  //     courseType: COURSE_TYPE.UG_DIPLOMA_CS,
  //     duration: '12 months',
  //     type: 'Part-time',
  //     classType: 'Online live classes',
  //     accredited: {
  //       woolf: true,
  //       iitGuwahati: true,
  //     },
  //     for: '1st, 2nd & 3rd Year College Students',
  //     outcome: `Internship + Diploma + 5+LPA Job`,
  //   },
  // ],
};

export const ENROLLED_IN = {
  CERTIFICATION: 'certification',
  MASTERS: 'masters',
  UG_DIPLOMA: 'ugCourses',
};

export const COURSE_INTEREST_COURSE_TYPE = {
  dataScienceInterest: COURSE_TYPE.DATA_SCIENCE,
  softDevInterest: COURSE_TYPE.WEB_DEV,
  mastersDSInterest: COURSE_TYPE.MASTERS_DS_AS,
  mastersCSInterest: COURSE_TYPE.MASTERS_CS,
  ugdiplomaCSInterest: COURSE_TYPE.WEB_DEV,
};

export const getGoalAndPlanBasedOnInterest = {
  [COURSE_TYPE.DATA_SCIENCE]: {
    goalType: COURSE_TYPE.DATA_SCIENCE,
    planType: PLAN_TYPE.PRO_LIVE,
  },
  [COURSE_TYPE.WEB_DEV]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.PRO_LIVE,
  },
  [COURSE_TYPE.MASTERS_DS_AS]: {
    goalType: COURSE_TYPE.DATA_SCIENCE,
    planType: PLAN_TYPE.MASTER,
  },
  [COURSE_TYPE.MASTERS_CS]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.MASTER,
  },
  [COURSE_TYPE.UG_DIPLOMA_CS]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.PRO_LIVE,
  },
};

export const LESSON_ACTIVITIES_IDS_FOR_REFINER = {
  Concept: 'e4645f10-41ba-11ee-9727-81770d4ea292',
  'Concept-Colab': 'e4645f10-41ba-11ee-9727-81770d4ea292',
  'Concept-Notion': 'e4645f10-41ba-11ee-9727-81770d4ea292',
  Video: '8aa794c0-4268-11ee-a2af-f9fae2984f65',
  Quiz: 'a89d84f0-426e-11ee-a2ca-9feca5aa8883',
  Code: '0a405710-4272-11ee-8ad9-99e125048ea3',
  Assignment: 'e4eca580-4272-11ee-aa24-fd4a05278182',
};

export const SUB_PLAN_TYPES = {
  PREMIUM_AB: 'premium_ab',
  ICONIC_IITG: 'iconic_iitg',
  ICONIC_WOOLF: 'iconic_woolf',
  GLOBAL_IITG_WOOLF: 'global_iitg_woolf',
};

export const SUB_PLAN_TYPES_MAPPING = {
  [SUB_PLAN_TYPES.PREMIUM_AB]: 'Premium',
  [SUB_PLAN_TYPES.ICONIC_IITG]: 'Iconic',
  [SUB_PLAN_TYPES.ICONIC_WOOLF]: 'Iconic',
  [SUB_PLAN_TYPES.GLOBAL_IITG_WOOLF]: 'Global',
};

export const EMI_PLANS_FOR_SUB_PLAN_TYPES = {
  [SUB_PLAN_TYPES.ICONIC_IITG]: '*Pay in easy EMIs starting at ₹ 3,333 per month',
  [SUB_PLAN_TYPES.ICONIC_WOOLF]: '*Pay in easy EMIs starting at ₹ 3,333 per month',
  [SUB_PLAN_TYPES.GLOBAL_IITG_WOOLF]: '*Pay in easy EMIs starting at ₹ 5,555 per month',
};

export const COURSE_TYPE_COURSE_NAME_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Data Science',
  [COURSE_TYPE.WEB3]: 'Web Development',
  [COURSE_TYPE.UG_DIPLOMA_CS]: 'UG Diploma CS',
};

export const STUDENTS_ID = [
  '35c4a48c-ee91-4ea7-8414-9e2de4dc0665',
  '9df1aa16-d8ff-4a6a-8e95-964ad3ea9716',
  'b61fc6e7-ffb8-4ea1-9fde-8ba11f0c33cb',
  '28a54afd-c908-4cd7-b4a3-ebca4df71935',
  '81ee6e35-5af9-4f10-be56-e6454eba8948',
  '58f4b455-5489-4953-868e-843cfb38f5fa',
  '9552c8c3-03d6-432b-beb7-74ceb92d63e4',
  'e5df6708-2d95-4c6e-bf6e-52f507f2b219',
  '019bd2a3-484b-4388-ba74-33cb5486d64b',
  '77a3e8e0-860b-4a21-a378-8595b85d7225',
  '954b6ad8-9932-4223-aba9-bc18a89c8f3a',
  '1f9b123c-c9f5-4e25-8190-5e6c8a1bdf0a',
  '1cbb5a9a-d70a-4a76-a708-19f4cb935bf7',
  'bd803bb2-e2cc-4602-99c9-f672db2b7b62',
  'c823e95b-1d8a-45e9-9ab6-d43cd2509bf2',
  '2c988157-9c99-4dac-8acc-85ebfc74c19c',
  '955c6ee5-ac8d-4f2f-a317-88dbbbb9860f',
  '1792c46e-68c0-4a1b-8c5f-2788afa90c9b',
  '2b23664d-48b9-4b47-9d76-5eb3f82392e0',
  'ce7203a3-2ea8-46b6-a322-e115228f07f4',
  '548d1ce2-6878-4f44-9d12-f3452b30cab8',
  '46effb9c-d247-44b6-a27e-a6ec57f36aa4',
  '27aeaa4a-7647-473f-94ed-6a6f29ea4a42',
  'f7a5d848-565d-461d-854a-71fd080cccff',
  '47818d57-1b5d-47d8-9b21-61de0e821a0a',
  '147936b1-7e49-4d8d-a202-bdc3bff77212',
  'fe137452-ee3e-46a6-9b2c-8f5bc8e30f07',
  '861d3b22-26ec-40e6-8132-8092c64038df',
  'dff1ccda-5be9-41a0-8f56-5c968a04c75b',
  '1363ee4a-b2a5-4a1f-9fad-1385d5cd2767',
  '930a9322-eaa2-40f9-af86-d0175cc2f5a2',
  '8565b9ef-2ff1-4d47-a820-aeee2d3c99a2',
  '0f08c4ca-69ee-4df9-9148-f03ea8cf9efa',
  '8aa9926b-8a73-410a-9997-da41059d445b',
  'c5299a6e-1bd2-418b-909c-3c0be65acbab',
  '64218980-7150-4eac-ad6f-cb7e3ba8850a',
  '78d711d4-f346-450f-a7d7-43da11734ace',
  '0e69578d-5a2a-477f-bdf2-e4e3bf836b55',
  '1310428b-4f68-48af-a430-a8febbbd6f74',
  'b3d87c84-aafb-4337-acdd-b6f1ea86032e',
  '9029e143-9030-4d20-9cb2-3c99b0528479',
  'c48a96b2-c1d4-47c1-8017-49a2b1954cc5',
  'feecef5d-a6b5-4fab-bad3-e67442fa2d0b',
  'd028e781-286f-4081-80d8-11c0652eb1ef',
  '5907e930-61e1-4398-93d6-ad07e945e81d',
  'b1a954e7-cc37-4983-8e3d-f3845978f645',
  'bbe93dd8-d310-4329-907b-141074eec69c',
  'add1fbd6-5018-40b2-9d1d-59cf6bb974e6',
  'a994cc76-f04a-41d9-a4ea-a9fef2b91f0a',
  '72247bae-77d3-47a0-a6f2-8c9f37803c41',
  'a84e03d8-5a04-4d09-9137-183d92a0694b',
  'bcf22c3d-3480-43cb-a1c6-cfad4258005d',
  'db7f311a-ed19-4fdd-9543-fefe05f6b3f0',
  '0d604d13-d83d-4904-adcd-b2ec52717f38',
  '230e60e2-c103-41eb-847b-851c3dccae89',
  'f0d39a16-5389-49f2-a73c-80a59c3f7ed0',
  'd0ed111d-cbd3-44bf-9336-8f77cc9d5827',
  '49ea3d06-9986-4976-8a24-9433d9a653b6',
  '5c58945d-4031-4727-bbc4-9f355ad5e227',
  'd86d6cdc-a076-4a6c-a165-fa950bd9e347',
  '85a6d01b-0bed-4f82-afb2-a565605dff5e',
  '6509e6e0-71e7-4060-a474-13dca4841952',
  'e83e3adb-27e3-43cd-a3d9-a41862c47e59',
  '06b13fd5-4ead-4024-8c33-b4ab5449f0d6',
  '0bb5b6d5-2d66-4a47-be45-c7210efeb77c',
  'ff7216bd-4fcb-4b9c-a58e-fea01b6d1130',
  'caa14a32-1bc6-4abe-a038-805611de6a72',
  '2679a601-e5c8-4191-a612-5c38cacc27d3',
  '725c2346-6a81-4ad3-ab7c-cf50834db2c4',
  '5938f476-e430-4eb2-b5bb-21d8c989c022',
  '7725f4a8-ab57-44c9-b63e-017678a878a6',
  '4b7eaab6-9a8f-4860-9af5-614a7c372fbd',
  '43d9840e-1e0a-4b78-a586-b571106aa10c',
  '2256e65d-ad1f-4c39-a242-f4f937381353',
  '66c8d995-9791-4244-a75e-d9509cd8a959',
  '8b1a5d2e-0bb7-4cb3-9be8-c0b6d893a669',
  '98d5ad9a-03d7-4e19-90cd-d4626c474d78',
  '3f5dc1e9-c0b7-4d8f-bd43-3b836c091860',
  '971f285f-bacb-47c3-9bb9-bb4fad3a80f9',
  'cea7f381-e91d-4c3b-a4de-2e6c85ac8da1',
  'cf77b9b5-1327-4655-8d40-602bf867dcc6',
  'fdbb1417-df09-4e1b-a7a7-14ce7b47fbdc',
  '06eeb117-aa7d-4afe-a079-a40cfaf5d70c',
  '9f306cef-8e62-4c4a-87ee-8f0841d33dc2',
  '6bdbfe19-28b9-4be4-abed-16b3a363242d',
  'e8f112eb-ea78-4f64-a366-35c327f01b69',
  'b0ec8bc8-2c57-4e24-ae2b-4497830d627c',
  'ece61510-ca88-4229-9156-6bb8490ef23d',
  '50e04c27-a914-4440-a360-2e229912d53f',
  '56adc669-4589-47e8-8b9f-60016eb84810',
  'c1f3d023-9f5a-4c38-b1e6-bd2e09ebd7b8',
  '53dea437-7ae4-47f9-bc00-351d1b2cb0c1',
  '1a941f9b-87cd-4df4-9896-9bd814547479',
  'ae103bca-0d1f-4479-a7d2-ce72f400769a',
  '71b9e3af-5d9b-4900-ae84-6910f788a5f0',
  'da52ed5f-ef40-44b1-992a-a170fdb781f7',
  '3dd3b81d-60e8-4e20-a5b3-1960c387a439',
  '202384c8-14cd-44b9-ab24-758a394d92e5',
  '98cc6e0b-58a7-4069-a479-c1946b1cd3a5',
  'b8c21f3e-6997-4f12-be5b-be3f8a6748ae',
  '96f6cc40-c8e2-4c55-a78f-92ffa191588b',
  '1aa430d8-7c31-488c-a061-9def762d43d3',
  '6997a93a-c101-4e39-819e-362c08b128bd',
  '10d78301-7c32-4504-b65b-be93f63f56c1',
  '0e457420-28da-4c97-9a45-61906bb96494',
  'f367e8c7-7403-442b-a67e-9a01ef2dd192',
  'bccc69fe-4557-4daf-99ff-299f2ff64244',
  '0f6b178b-4615-42ba-ad45-fe72186daed6',
  'dbc009be-69ee-44a5-a60f-87d7c4b0879d',
  '192389dc-bdf2-4f14-bd6f-298eba59e3b7',
  '7f7d8c2a-8025-4921-91be-4a6bb53e12ae',
  '3db2d898-7284-4cb7-b04a-bfa09e2e548b',
  'daf21153-45fe-413b-9213-d799f7c2710d',
  'ea203728-d1ae-41dc-8e67-94b23b5f4aae',
  '5cbdde06-bd49-4ce3-9c3f-09ac2a44f898',
  'd6d39e6f-4388-41cc-b70c-acf6a88f4d5d',
  'eed16aac-b9a1-4aa3-9eff-f3d9872890b2',
  '964333df-f36c-41b6-ad65-fcc115749fac',
  'b4397eef-6955-4f00-919f-e4058543d16f',
  'da574b21-8ce3-45ed-af9a-d0f983d9afa0',
  'fb605cf4-3773-11ef-acae-0277db336423',
  'a8da8aec-786e-4dea-862d-c1cabc482e76',
  '3ee4402e-59df-428e-a4f0-844a361258cd',
  '7ed72389-eacc-4875-8c16-e7850ee42011',
  '7c0adcec-ca2f-40ad-8bd6-92858592885c',
  '5384c5d1-0430-4af2-b2a3-e5c8d4c8df70',
  '708e5720-fcd6-4763-873b-a47d9a072d1e',
  'e1682aef-f9f9-4bf2-bb4b-7fee1091c08a',
  '9870a560-ac7f-4bc6-b973-531af06412b8',
  '87722436-e589-4a36-8f4e-72bd187f08e0',
  '812761c9-fc26-4532-9f43-1cd05060322d',
  '11fc41c6-c785-4abd-975f-6573388d75ae',
  'f53b0589-8fd7-469d-ab01-22c23a268755',
  'd69ba8b8-0d4b-4873-95fd-c46f82fc8d6d',
  'dc4468bf-620f-4105-a1a5-8182dba17405',
  '45f0e771-8648-41b6-9e41-0e578473e059',
  '2494d9ad-9ee3-4f86-b4cc-f0f71911eb9b',
  'd858d20d-4406-4488-8da9-39eadea3dd2a',
  'ea9b3327-e999-49d6-bb68-1078fddbc5d7',
  '5e10a6fd-0e77-40b5-be0a-93b1586684d0',
  '0086ac9f-a425-4807-b587-09df5a76920f',
  '727161cf-de70-4492-bf19-885f8acdf883',
  '51f13159-e3a4-468e-a848-0146607148de',
  'c9957c22-07ad-4155-bdc3-32301602f4ff',
  '088694bf-d330-46b5-a8d0-ebf17148b1fc',
  '7869f30d-0a4b-4f63-97ec-86a4ea28bbbe',
  '7f608264-4976-4dc4-8c8f-f357103db1b0',
  'fa8a7def-dad3-46a2-b014-8a44196c8b83',
  '27c0aba6-6e6f-4f8e-b29d-9e04b3058ef5',
  '8236bf2b-0891-40c4-98b8-cce760815b76',
  '2b8466d0-2a3d-4fd4-bb0e-c021f8a74550',
  '3717025c-2268-4d51-8ddd-bfb8d6e6b9ef',
  'd01d6792-e54d-42d8-aea8-4f066e727476',
  '7b8f42eb-5bd3-4cbb-b939-9de3a51360ee',
  'aa27347d-c2b6-4743-aef2-2122c92062c7',
  'a85ba268-44e1-4676-bf4c-772d82c1b792',
  'ef82532b-ab1f-4794-8a9d-63cb514a47cc',
  'b8a91aa8-ca42-428b-b925-d374ba048a7a',
  '5bebfd36-001b-41b6-bbf0-619eaae2b0af',
  'd16d30cb-b75f-4e29-b8b0-df5a0f6c195a',
  '2bdad9d9-e65c-4969-b84b-7ca43adc314c',
  '2c51f7a5-470c-431b-8731-417010715d10',
  '270239a0-eae7-473c-9811-366d33ad37cf',
  'd72eb1fa-9904-42a1-88b3-d5b9c28dc404',
  'ae3c58ac-fb41-466f-964a-db3a43a4f948',
  '5c9af179-2ea9-430a-8541-1b879b3a9c6e',
  '7bf8f655-ec3f-4e51-94c7-a51a890ccd33',
  '1057320c-062c-4aca-a9a1-31bd7db204e2',
  '14a6ba71-5125-4aab-8d60-85e4292a4e1c',
  '6d3971c9-d46f-495b-88dc-ada53b52a801',
  'bdee3a22-4928-4eed-9e1c-c3e622479bef',
  '949ec566-3294-40c8-b2d2-caa457d777f1',
  'c82213df-bc75-495a-9fe0-e902d0baf203',
  '764b3288-ed47-40f7-9cfd-e314059bba01',
  '8ee55d51-0ecd-4780-9237-e10847c4ad82',
  'caf98358-e823-443d-bc78-14d1eb5ef4c6',
  '6549b23f-a009-433c-8f75-9518db26401b',
  '467abc24-4d66-4766-b59d-ae3d4565f62f',
  '2e4b4e8a-642e-43cb-891d-7fd6871b5204',
  'fbbd3286-7eb1-42f4-8b15-f4123adafd4c',
  '025325c1-0767-4be7-8217-7896442509c0',
  '8bb8ac68-f757-4046-b0ba-2d1fc59d965e',
  'b707db9e-2d4e-4b73-acff-df99d3a0ffcd',
  'cd94fc8e-0c65-45c6-852a-f468ff59179b',
  '23d5b7f6-3bbc-483d-aeb0-224869e86730',
  'd15c2f40-2906-43c4-b5db-708baeb194ad',
  '0120a46d-bd20-4d5d-b6cb-7d93e86febb5',
  '83b93744-c137-4794-898c-86950922d257',
  'e2f40041-b446-4e32-b925-130dcec4374b',
  'f9aa42b8-5367-4cb6-bd81-19b2ba148e45',
  '6d58f290-249c-40f7-8e66-6910e5e12562',
  'ee42f0e4-f22e-484a-aeda-cf50ad98f135',
  '10a760a7-a368-456b-9827-14d0453f9a41',
  '18bb74d6-9db0-4b5a-81c9-efd2b858bbf0',
  'dabdb1e5-acb4-48c7-a57b-52a9a383643d',
  '54c216e5-67cb-4b15-9e46-005f867f4666',
  'a2249235-0635-4a34-893d-1c24cfae0244',
  '866e5948-561c-4153-9232-a6d06f051466',
  '82e1633f-16d5-42c0-9d97-a825e29f2b12',
  'bd651a34-5ae0-4a9f-a0b2-de1e6d081908',
  '65af03e4-f0ac-4111-85e6-b007f3ae2b64',
  '3a5a39ca-415c-4924-96c6-09f450a3076b',
  'd7096f92-1ff1-4cde-bcb8-7b5450fd265c',
  '4c10f29c-d1f1-4a46-bbb3-0b204efc31f2',
  '2772eb92-4832-4e90-8cc4-4b28c8e3c9be',
  '4684c292-f968-4561-a558-816f2e2126b1',
  '5d6660ac-76a2-4073-944f-c279c08de36d',
  'f8a9642d-9a28-4166-9ad8-18afe5fca249',
  '550e997c-851d-404f-b1da-be10e5e0d51c',
  '5f318363-be8f-45cc-81b0-22721a8690a4',
  '3e84eac7-ea48-45cb-942c-c292743a6f60',
  '097d23ae-d076-45f6-bec8-cd259aa01d8c',
  '50249e49-fbce-44f1-ad73-ad6ca19c96ee',
  'a15bd76e-1aba-47db-87a7-be3304599ac5',
  'a0cd6b34-e616-4739-aafe-08e276a4cf9d',
  '4b97a33c-a7f6-4aad-9b5a-62e444ab79a8',
  'c590a21b-d949-4136-b716-071f4c221747',
  '8890503f-583e-4882-a33c-a5bfa5371fab',
  '9a7edb00-ecf3-4995-9347-e7082a056a62',
  '6aeaac78-80ae-4ee0-aa4a-97d431992b47',
  'c537aaba-f3b5-444e-baa1-5c65b10ef2c1',
  'fc4930ad-40b6-4999-b855-a227a42f769f',
  '3d5fbcb2-2776-4eaa-9b1a-4fad13234983',
  '63b51dd2-3d45-48ea-bac2-aaf2c18c4800',
  '79fd5bbe-32f6-4003-a928-7db891232ce1',
  'b6eea4d2-ffe9-4793-9049-65f4229484db',
];
