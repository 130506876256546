module.exports = {
  AB_GREEN_C1: '#134F5C',
  AB_GREEN_C2: '#47C71A',
  AB_GREEN_C3: '#76CE65',
  AB_GREEN_C4: '#359D91',
  AB_GREEN_C5: '#E2FBEF',
  AB_GRAY_C0: '#FAFAFA',
  AB_GRAY_C1: '#E5E5E5',
  AB_GRAY_C3: '#D2DDE8',
  AB_GRAY_C4: '#ffffff14',
  AB_GRAY_C5: '#0f0f0b',
  AB_GRAY_C6: '#ffffff1a',
  AB_GRAY_C7: '#00000099',
  AB_GRAY_C8: '#0000001a',
  AB_GRAY_C9: '#8492a6',
  AB_GRAY_C10: '#5D6672',
  AB_GRAY_C11: '#CAD7E5',
  AB_GRAY_C12: '#515151',
  AB_GRAY_C13: '#EEE',
  AB_GRAY_C14: '#666666',
  AB_GRAY_C15: '#F4F4F4',
  AB_GRAY_C16: '#8491A0',
  AB_BLUE_C1: '#148EFF',
  AB_BLUE_C2: '#0070E0',
  AB_BLUE_C3: ' #5258F5',
  AB_BLUE_C4: '#114ADC',
  AB_BLUE_C5: '#1F50FD',
  AB_BLUE_C6: '#F5F8FF',
  AB_BLUE_C7: '#1C5DDB',
  AB_BLUE_C8: '#E2EDFB',
  AB_BLUE_C9: '#5E6E88',
  AB_YELLOW_C1: '#FDA623',
  AB_YELLOW_C2: '#F8ECDE',
  AB_YELLOW_C3: '#C07E1A',
  AB_PINK_C1: '#FDB5B5',
  AB_PINK_C2: '#FAEFEF',
  AB_PRIMARY: '#000',
  AB_PRIMARY_SHADE_1: '#1C1C25',
  AB_PRIMARY_SHADE_2: '#333333',
  AB_PRIMARY_SHADE_3: '#101010',
  AB_PRIMARY_SHADE_4: 'rgba(0,0,0,0.3)',
  AB_PRIMARY_SHADE_5: 'rgba(0,0,0,0.8)',
  AB_SECONDARY: '#cc0000',
  AB_SECONDARY_SHADE_1: '#E50914',
  AB_SECONDARY_SHADE_2: '#ad0510',
  AB_SECONDARY_SHADE_3: '#ff4b55',
  AB_SECONDARY_SHADE_4: '#ff1111',
  AB_SECONDARY_SHADE_5: '#F00037',
  AB_SECONDARY_SHADE_6: '#FAE0E3',
  AB_SECONDARY_SHADE_7: '#7B152A',
  AB_TERTIARY: '#fff',
  AB_TERTIARY_SHADE_1: 'rgba(255,255,255,0.7)',
  AB_TERTIARY_SHADE_2: '#F8F8FA',
  AB_LIGHT: {
    YELLOW_SHADE_1: '#FDF8F1',
    YELLOW_SHADE_2: '#FAE9DE',
    BLUE_SHADE_1: '#1B252F',
  },
  AB_ORANGE_LIGHT: '#F9EBEB',
  AB_MODULE_THEME: '#fafbff',
  AB_GRAY_C17: '#7B8698',
  AB_GRAY_C18: '#D7E1EA',
  AB_RED_R1: '#FAEFEF',
  AB_PINK_HOVER: '#FBEAEA',
  AB_TYPE_COLOUR_1: '#5E6E88',
  AB_TYPE_COLOUR_2: '#8E99AC',
  AB_PASTEL_PURPLE_1: '#ECEFFE',
  AB_SHADE_GRAY: '#00000005',
};
