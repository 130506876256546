import { createSlice } from '@reduxjs/toolkit';
import { currentLessonActivityStatus } from 'src/modules/course-lms-v2/components/local/learnLesson/lesson/utils';
import {
  chapterOrderSet,
  complimentaryChapterOrderSet,
  returnLastLessonName,
} from 'src/modules/course-lms-v2/components/shared/utils';
import { returnCourseApiName } from 'src/services/getcourse';
import { returnActualCourseNameForApi } from '../services/getcourse';
import {
  courseData,
  fetchAllActivitiesStatus,
  fetchAllClasses,
  fetchShortCourseCardData,
  fetchShortCourseData,
  getComplimentaryLastLesson,
  getLastLesson,
  submitClassroomFeedback,
  updateLessonActivityStatus,
} from '../services/learnTabApiService';
import { setIsUpcomingWithin48Hours } from './homeSlice';

const initialState = {
  currentChapter: {
    module: 0,
    chapter: 0,
    problems: [],
    currentSelectedProblem: 0,
    totalModules: 0,
  },
  courseData: null,
  headerIcon: {
    reset: false,
    settings: false,
    fullscreen: false,
  },
  selectLanguage: 'javascript',
  code: {
    python: '',
    javascript: '',
    html: '',
    css: '',
    react: '',
    java: '',
  },
  shortCourse: {
    cardData: [],
    shortCourseData: {},
  },
  updateSubmission: false,
  lastLesson: null,
  lastLessonComplimentary: null,
  currentChapterIndex: null,
  noLastLesson: false,
  classroom: null,
  lastAccessedLesson: null,
  activityStatus: null,
  actualActivityData: null,
  practiseData: null,
  completeLessonLoader: false,
  completedActivities: {},
  mobileModuleSelected: null,
  selectedTrack: null,
  shortCourseLoader: false,
};

const learnTabSlice = createSlice({
  name: 'learnTabReducer',
  initialState,
  reducers: {
    setCurrentChapter: (state, action) => {
      state.currentChapter.chapter = action.payload.chapter;
    },
    setShortCourseCardData: (state, action) => {
      state.shortCourse.cardData = action.payload;
    },
    updateShortCourseData: (state, action) => {
      state.shortCourse.shortCourseData = action.payload;
    },
    setPractiseData: (state, action) => {
      state.practiseData = action.payload;
    },
    setCurrentProblem: (state, action) => {
      state.currentChapter.currentSelectedProblem = action.payload;
    },
    setCurrentModule: (state, action) => {
      state.currentChapter.module = action.payload;
    },
    setTotalModules: (state, action) => {
      state.currentChapter.totalModules = action.payload;
    },
    setCourseData: (state, action) => {
      state.courseData = action.payload;
    },
    setMobileModuleSelected: (state, action) => {
      state.mobileModuleSelected = action.payload;
    },
    setSelectedTrack: (state, action) => {
      state.selectedTrack = action.payload;
    },
    updateHeaderIcon: (state, action) => {
      state.headerIcon[action.payload.icon] = action.payload.value;
    },
    updateLanguage: (state, action) => {
      state.selectLanguage = action.payload;
    },
    resetLanguage: (state, action) => {
      state.code = {
        python: '',
        javascript: '',
        html: '',
        css: '',
        react: '',
        java: '',
      };
    },
    updateCode: (state, action) => {
      state.code[state.selectLanguage] = action.payload;
    },
    setUpdateSubmissions: (state, action) => {
      state.updateSubmission = action.payload;
    },
    setLastLesson: (state, action) => {
      if (action.payload?.length === 0 || typeof action.payload !== 'object') {
        state.noLastLesson = true;
      }
      state.lastLesson =
        typeof action.payload === 'object' && action.payload?.length > 0 ? action.payload?.[0] : {};
    },
    setNoLastLesson: (state, action) => {
      state.noLastLesson = action.payload;
    },
    setCurrentChapterIndex: (state, action) => {
      state.currentChapterIndex = action.payload;
    },
    setLastLessonComplimentary: (state, action) => {
      state.lastLessonComplimentary = action.payload;
    },
    setClassroom: (state, action) => {
      state.classroom = action.payload;
    },
    setLastAccessedLesson: (state, action) => {
      state.lastAccessedLesson = action.payload;
    },
    setActivityStatus: (state, action) => {
      if (action.payload?.status) {
        state.activityStatus = action.payload.status;
      }
      if (action.payload?.data) {
        state.actualActivityData = action.payload.data;
      }
    },
    setCompleteLessonLoader: (state, action) => {
      state.completeLessonLoader = action.payload;
    },
    setCompletedActivities: (state, action) => {
      state.completedActivities = action.payload;
    },
    updateShortCourseLoader: (state, action) => {
      state.shortCourseLoader = action.payload;
    },
  },
});

export const getLastAccessedLesson = (state) => state.learnTabReducer.lastAccessedLesson;
export const shortCourseCardData = (state) => state.learnTabReducer.shortCourse.cardData;

export const fetchLastAccessedLesson = () => {
  return async (dispatch, getState) => {
    try {
      const courseInfo = getState().learnTabReducer.courseData;
      const lastLesson = getState().learnTabReducer.lastLesson;
      const data = returnLastLessonName(courseInfo, lastLesson);
      dispatch(setLastAccessedLesson({ ...data }));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getShortCourseData = (slug) => {
  return async (dispatch, getState) => {
    try {
      dispatch(updateShortCourseLoader(true));
      const result = await fetchShortCourseData(slug);
      const sortedData = complimentaryChapterOrderSet(result);
      dispatch(fetchComplimentaryLastLesson(sortedData?.id));
      dispatch(updateShortCourseData(sortedData));
    } catch (error) {
      dispatch(updateShortCourseData({}));
      console.error(error);
    } finally {
      dispatch(updateShortCourseLoader(false));
    }
  };
};

export const getActivityStatus = (activityData) => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const courseApiName = returnCourseApiName(actualCourseSelected);
      const selectedCourseSubscription = userSubscription?.[courseApiName] ?? null;
      if (!selectedCourseSubscription) return [];
      const res = await fetchAllActivitiesStatus(activityData?.lessonId);
      const allStatus = currentLessonActivityStatus(res);
      await dispatch(setActivityStatus({ status: allStatus, data: res }));
      return allStatus;
    } catch (error) {
      console.error(error);
    }
  };
};

export const postLessonActivityStatus = (activityData) => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const courseApiName = returnCourseApiName(actualCourseSelected);
      const selectedCourseSubscription = userSubscription?.[courseApiName] ?? null;
      if (!selectedCourseSubscription) return null;
      return await updateLessonActivityStatus(activityData);
    } catch (error) {
      console.error(error);
    }
  };
};

export const getClassroomData = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = returnCourseApiName(getState().courseReducer.courseSelected);
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const result = await fetchAllClasses(modifiedCourse);

      const currentDate = new Date().getTime();
      const upcomingData = result.filter((item) => {
        const itemTime = new Date(item.endTime).getTime();
        return itemTime > currentDate;
      });

      const completedData = result.filter((item) => {
        const itemTime = new Date(item.endTime).getTime();
        return itemTime <= currentDate;
      });
      dispatch(
        setClassroom({
          upcoming: upcomingData,
          completed: completedData,
        })
      );
      // dispatch(
      //   setIsUpcomingWithin48Hours(
      //     upcomingData?.some((item) => {
      //       const itemTime = new Date(item.startTime).getTime();
      //       return itemTime > currentDate;
      //     })
      //   )
      // );
    } catch (error) {
      console.error(error);
    }
  };
};

export const successCallbackAfterFeedbackSubmission = (params, responseData) => {
  return async (dispatch, getState) => {
    delete responseData?.email;
    delete responseData?.activityId;
    const apiData = {
      activityId: params?.activityId,
      feedback: responseData,
    };
    const res = await submitClassroomFeedback(apiData);
    if (res) {
      dispatch(getClassroomData());
    }
  };
};

export const getShortCourseCardData = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const result = await fetchShortCourseCardData(modifiedCourse);
      dispatch(setShortCourseCardData(result));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCourseData = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const apiCourseName = returnCourseApiName(actualCourseSelected || courseSelected);
      const param =
        userSubscription?.[apiCourseName]?.paymentStage === 'reserve_seat' ||
        !userSubscription?.[apiCourseName]
          ? actualCourseSelected?.includes('masters')
            ? 'planType=masters'
            : 'planType=pro_live'
          : null;
      const result = await courseData(modifiedCourse, param);
      const data = result?.data?.[0]?.attributes;
      const obj = {
        BASIC_INFO: { ...data },
        courseId: result?.data?.[0]?.id,
      };
      data?.learn_tracks?.data?.forEach((item) => {
        const attributes = item?.attributes;
        const type = attributes?.trackType;
        if (type) {
          const trackType = type.includes('foundation')
            ? 'FOUNDATION_TRACK'
            : type.includes('industry')
            ? 'INDUSTRY_TRACK'
            : 'SPECIALISATION_TRACK';

          obj[trackType] = {
            ...chapterOrderSet(attributes),
            id: item?.id,
            courseId: result?.data?.[0]?.id,
          };
        }
      });

      dispatch(setCourseData(obj));
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchlastLesson = (data) => {
  return async (dispatch, getState) => {
    try {
      const result = await getLastLesson(data);
      dispatch(setLastLesson(result));
      return result;
    } catch (error) {
      console.error(error);
      dispatch(setLastLesson([]));
    }
  };
};

export const fetchComplimentaryLastLesson = (courseId) => {
  return async (dispatch, getState) => {
    try {
      const result = await getComplimentaryLastLesson(courseId);
      dispatch(setLastLessonComplimentary(result?.lessonId));
    } catch (error) {
      console.error(error);
    }
  };
};

export const {
  setCurrentChapter,
  setCurrentProblem,
  setCurrentModule,
  setTotalModules,
  setCourseData,
  updateHeaderIcon,
  updateLanguage,
  updateCode,
  setUpdateSubmissions,
  setLastLesson,
  setCurrentChapterIndex,
  setLastLessonComplimentary,
  setNoLastLesson,
  resetLanguage,
  setClassroom,
  setLastAccessedLesson,
  setActivityStatus,
  setPractiseData,
  setCompleteLessonLoader,
  setCompletedActivities,
  setMobileModuleSelected,
  setSelectedTrack,
  setShortCourseCardData,
  updateShortCourseData,
  updateShortCourseLoader,
} = learnTabSlice.actions;

export default learnTabSlice.reducer;
